// JavaScript Document

// vendor

import jQuery from "jquery";
import Swiper from "swiper";

// custom

document.addEventListener("DOMContentLoaded", function () {
    /* HOME BANNER SLIDER JS */

    new Swiper(".BannerSwiper", {
        loop: true,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });

    /* MOBILE MENU JS */

    jQuery("#Mobile-Menu_toggle").on("click",function () {
        jQuery(".mobile-header").toggleClass("Mobile-menu-open");
        jQuery("body").toggleClass("nav_open");
    });

    /* SEARCH TOGGLE JS */

    jQuery("button#find-it-btn").on("click", function () {
        const search_type = jQuery("#search_type").val();
        let search = jQuery("#search").val();
        search = encodeURIComponent(search);
        if (search_type != "" && search != "") {
            if (search_type == "catalog") {

                window.open(`https://prcat.na2.iiivega.com/search?query=${search}&searchType=everything&pageSize=10`, "_blank");
            } else {
                return true;
            }
        }
        return false;
    });

    jQuery("button#find-it-btn-mobile").on("click", function () {
        console.log("clicked");
        const search_type = jQuery("#search_type_mobile").val();
        let search = jQuery("#search_mobile").val();
        search = encodeURIComponent(search);
        if (search_type != "" && search != "" && document.querySelector(".mobile-header .search-bar").classList.contains("search-bar-open")) {
            if (search_type == "catalog") {
                window.open(`https://prcat.na2.iiivega.com/search?query=${search}&searchType=everything&pageSize=10`, "_blank");
            } else {
                return true;
            }
        }
        jQuery(".mobile-header .search-bar").toggleClass("search-bar-open");
        return false;
    });

});
