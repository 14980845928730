// JavaScript Document

// Scripts written by __gulp_init_author_name__ @ __gulp_init_author_company__

import Swiper from "swiper";

const BOOK_LISTS = document.querySelectorAll(".swiper-container--book-list");

BOOK_LISTS.forEach((book_list) => {
    new Swiper(book_list, {
        loop: true,
        navigation: {
            nextEl: book_list.parentElement.querySelector(".swiper-button--next"),
            prevEl: book_list.parentElement.querySelector(".swiper-button--prev"),
        },
        slidesPerView: 5,
        spaceBetween: 20,
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            480: {
                slidesPerView: 2,
            },
            560: {
                slidesPerView: 3,
            },
            640: {
                slidesPerView: 4,
            },
        },
    });
});
